






















import { Component, Emit, Vue } from 'vue-property-decorator'
import Button from '@/components/Button.vue'

@Component({
  components: {
    Button
  }
})
export default class BackButton extends Vue {
  @Emit()
  click() {
    // placeholder
  }
}
