



































































































































import { Component } from 'vue-property-decorator'
import Loading from '@/components/Loading.vue'
import Tile from '@/components/Tile.vue'
import Button from '@/components/Button.vue'
import BackButton from '@/components/BackButton.vue'
import ImageUpload from '@/components/ImageUpload.vue'
import WeekdayChooser from '@/components/WeekdayChooser.vue'
import ConfirmationDialog from '@/components/ConfirmationDialog.vue'
import { mixins } from 'vue-class-component'
import { FarmItemType, mapFarmItemType } from '@/models/Types'
import ControlMixin from '@/mixins/ControlMixin.vue'
import firebase, { firestore } from 'firebase/app'
import { showNotification } from '@/services/NotificationService'
import UserMixin from '@/mixins/UserMixin.vue'
import { FarmItem } from '@/models/FarmItem'
import { DropdownOption } from '@/models/DropdownOption'
import { FarmItemConverter } from '@/models/converters/FarmItemConverter'
import { round } from '@/services/NumberService'
import { deleteFromUrl, getDownloadUrl, uploadFile } from '@/services/Storage'

@Component({
  components: {
    Loading,
    Tile,
    Button,
    BackButton,
    ImageUpload,
    WeekdayChooser,
    ConfirmationDialog
  }
})
export default class AdminFarmItemDetail extends mixins(
  UserMixin,
  ControlMixin
) {
  loading: boolean = true
  saving: boolean = false
  deleteDialogVisible: boolean = false
  item: FarmItem = null
  image: File = null
  previousImageUrl: string = null
  types: Array<DropdownOption<FarmItemType>> = [
    { text: mapFarmItemType('extra'), value: 'extra' },
    { text: mapFarmItemType('feed'), value: 'feed' }
  ]
  farmId: string = null

  get isNew() {
    return this.$route.params.itemId === 'neu'
  }

  get amount() {
    return this.item.amount / 100
  }

  set amount(value: number) {
    const parsed = parseFloat(value.toString().replace(/[^\d\.]/g, ''))
    if (isNaN(parsed)) {
      return
    } else {
      this.item.amount = round(parsed * 100)
    }
  }

  async created() {
    this.farmId = this.$route.params.farmId

    if (this.isNew) {
      this.item = new FarmItem()
      this.item.name = 'Neues Produkt'
    } else {
      this.item = (
        await firestore()
          .collection('farms')
          .doc(this.farmId)
          .collection('items')
          .doc(this.$route.params.itemId)
          .withConverter(new FarmItemConverter())
          .get()
      ).data()
    }

    this.loading = false
    this.focusInput('name')
  }

  goBack() {
    this.$router.push({ name: 'admin/farm/items' })
  }

  imageChanged(file: File) {
    this.previousImageUrl = this.item.imageUrl
    this.item.imageUrl = URL.createObjectURL(file)
    this.image = file
  }

  async deleteItem() {
    await this.deleteImage(this.item.imageUrl)
    await this.item.reference.delete()
    showNotification({
      title: 'Erfolg',
      text: 'Das Produkt wurde gelöscht.',
      type: 'success',
      timeout: 10
    })
    this.goBack()
  }

  async deleteImage(url: string) {
    if (url) {
      // Previous photo exists => delete
      try {
        await deleteFromUrl(url)
      } catch (ex) {
        // No previous file exists => nothing to do
      }
    }
  }

  async uploadImage() {
    if (this.image) {
      try {
        const imagePath = `/farms/${this.farmId}/items/${this.item.id}/${this.image.name}`
        await uploadFile(imagePath, this.image)
        this.item.imageUrl = await getDownloadUrl(imagePath)
      } catch (ex) {
        // TODO show error
      }
    }
  }

  async save() {
    this.saving = true

    if (this.isNew) {
      this.item = (
        await (
          await firebase
            .firestore()
            .collection('farms')
            .doc(this.farmId)
            .collection('items')
            .withConverter(new FarmItemConverter())
            .add(this.item)
        ).get()
      ).data()

      await this.uploadImage()
      await this.item.reference
        .withConverter(new FarmItemConverter())
        .set(this.item, { merge: true })

      this.item = (
        await this.item.reference.withConverter(new FarmItemConverter()).get()
      ).data()

      this.$router.push({
        name: 'admin/farm/items-detail',
        params: { farmId: this.farmId, itemId: this.item.id }
      })
    } else {
      await this.deleteImage(this.previousImageUrl)
      await this.uploadImage()
      await this.item.reference
        .withConverter(new FarmItemConverter())
        .set(this.item, { merge: true })
    }

    this.saving = false
    showNotification({
      title: 'Erfolg',
      text: 'Die Änderungen wurden gespeichert.',
      type: 'success',
      timeout: 10
    })
  }
}
